import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import InfoCard from 'components/InfoCard';
import Image from 'components/Image';
import { TRENDING_POSTS_IMAGE } from 'utils/constants';

import AdminOnlyIndicator from 'components/AdminOnlyIndicator';
import styles from './ExplanationCard.module.scss';

const cx = classNames.bind(styles);

const ExplanationCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={cx('explanation-card')}>
      <InfoCard
        dataQA="trending-posts-explanation-card"
        localStorageKey="hideTrendingPostsEmptyState"
        title={t('components-trendingPosts_title', 'Trending posts')}
        isCompact
        isDismissable
      >
        <p>
          {t(
            'components-trendingPosts-explanation_text',
            'Posts with the most engagement will show up here, driving even more visibility and momentum.'
          )}
        </p>
        <h5>{t('common_PREVIEW', 'PREVIEW')}</h5>
        <Image height={582} src={TRENDING_POSTS_IMAGE} width={690} />
        <AdminOnlyIndicator />
      </InfoCard>
    </div>
  );
};

export default ExplanationCard;
