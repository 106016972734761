import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { P } from '@thinkific/toga-react';

import { useCommunityContext } from 'components/CommunityContext';
import { POST_LIST_QUERY } from 'schema/Post/queries';
import { NUMBER_OF_REACTIONS, TRENDING_POSTS_MAX } from 'utils/constants';
import {
  PostListQuery,
  PostListQueryVariables,
  PostOrderField,
} from '__generated__/graphql/legacy/graphql';
import { getTotalNumberOfReplies } from 'helpers/postHelpers';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import TrendingPost from './TrendingPost/TrendingPost';
import TrendingPostsSkeleton from './TrendingPostsSkeleton/TrendingPostsSkeleton';
import ExplanationCard from './ExplanationCard/ExplanationCard';

import styles from './TrendingPosts.module.scss';

const cx = classNames.bind(styles);

const TrendingPosts: React.FC = () => {
  const { t } = useTranslation();
  const { community } = useCommunityContext();

  const { loading, data } = useQuery<PostListQuery, PostListQueryVariables>(
    POST_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        communityId: community.id,
        numOfPosts: TRENDING_POSTS_MAX,
        postOrderField: PostOrderField.TrendingScore,
        filter: {
          trending: true,
        },
        numberOfReactions: NUMBER_OF_REACTIONS,
      },
    }
  );

  const posts = data?.community?.posts?.edges || [];

  if (loading) {
    return (
      <VisibilityCloak admins>
        <TrendingPostsSkeleton />
      </VisibilityCloak>
    );
  }

  if (posts.length === 0) {
    return (
      <VisibilityCloak admins>
        <ExplanationCard />
      </VisibilityCloak>
    );
  }

  return (
    <div className={cx('trending-posts')} data-qa="trending-posts">
      <P className={cx('trending-posts__title')}>
        {t('components-trendingPosts_title', 'Trending posts')}
      </P>
      <ul className={cx('trending-posts__list')}>
        {posts.map((edge) => {
          const post = edge?.node;

          if (!post) return null;

          const totalCommentCount = getTotalNumberOfReplies(post);

          return (
            <TrendingPost
              author={post?.user}
              commentCount={totalCommentCount}
              id={post.id}
              key={post.id}
              title={post.title || ''}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default TrendingPosts;
