import React from 'react';

import { useTranslation } from 'react-i18next';

import Opacity from 'components/Opacity/Opacity';
import { useEventPreviewContext } from 'components/Contexts/EventPreview/EventPreviewContext';
import EventCard from '../EventCard/EventCard';

const EventPreviewCard: React.FC = () => {
  const { t } = useTranslation();

  const {
    imageUrl,
    altText,
    title,
    startTime,
    endTime,
    timezone,
  } = useEventPreviewContext();

  const titlePlaceholder = t(
    'components-forms-weekly_check_in_placeholder',
    'eg. Weekly Check-in, Q&A...'
  );

  return (
    <Opacity value={0.6}>
      <EventCard
        endTime={endTime}
        imageAltText={altText}
        imageUrl={imageUrl}
        startTime={startTime}
        timezone={timezone || undefined}
        title={title || titlePlaceholder}
        isPreview
      />
    </Opacity>
  );
};

export default EventPreviewCard;
