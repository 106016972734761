import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Icon } from '@thinkific/toga-react';

import InfoCard from 'components/InfoCard';
import Button from 'koba/components/Button';
import AdminOnlyIndicator from 'components/AdminOnlyIndicator';
import { useCommunityContext } from 'components/CommunityContext';
import { getPublishUrl, getSettingsUrl, routes } from 'utils/routerHelpers';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { eventNames } from 'constants/eventNames';

import styles from './CommunityChecklist.module.scss';

const cx = classNames.bind(styles);

type ChecklistOption = {
  text: string;
  icon: string;
  link?: string;
  onClick: () => void;
  dataQA: string;
  key: string;
};

const CommunityChecklist: React.FC = () => {
  const { community, trackEvent } = useCommunityContext();

  const { t } = useTranslation();

  const trackNavigationEvent = (event) => {
    trackEvent(event, {
      [TrackingProperty.COMPONENT]: 'CommunityChecklist',
    });
  };

  const publishOpenModal = () => {
    trackEvent(TrackingEvent.POST_MODAL_OPENED, {
      [TrackingProperty.COMPONENT]: 'CommunityChecklist',
    });

    PubSub.publish(eventNames.OPEN_POST_MODAL);
  };

  const checklistOption = (option: ChecklistOption) => (
    <Button
      appearance="link"
      className={cx('community-checklist-option__button')}
      data-qa={option.dataQA}
      href={option.link}
      key={option.key}
      onClick={option.onClick}
    >
      <span className={cx('community-checklist-option__container')}>
        <span className={cx('community-checklist-option__icon-container')}>
          <Icon
            className={cx('community-checklist-option__icon')}
            name={option.icon}
          />
        </span>
        {option.text}
      </span>
    </Button>
  );

  const communityChecklistOptions: ChecklistOption[] = [
    {
      text: t(
        'components-communityChecklist-set_up_profile',
        'Set up your profile'
      ),
      icon: 'smiley-face',
      link: routes.myAccount,
      onClick: () => trackNavigationEvent(TrackingEvent.GO_TO_PROFILE),
      dataQA: 'community-checklist-option__button-profile',
      key: 'components-communityChecklist-set_up_profile',
    },
    {
      text: t(
        'components-communityChecklist-welcome_post',
        'Publish a welcome post'
      ),
      icon: 'page-new',
      link: undefined,
      onClick: publishOpenModal,
      dataQA: 'community-checklist-option__button-welcome-post',
      key: 'components-communityChecklist-welcome_post',
    },
    {
      text: t(
        'components-communityChecklist-customize_community',
        'Customize community'
      ),
      icon: 'cog',
      link: getSettingsUrl(community.id),
      onClick: () =>
        trackNavigationEvent(TrackingEvent.GO_TO_CUSTOMIZE_COMMUNITY),
      dataQA: 'community-checklist-option__button-customize-community',
      key: 'components-communityChecklist-customize_community',
    },
    {
      text: t(
        'components-communityChecklist-set_community_access',
        'Set community access'
      ),
      icon: 'user-logout',
      link: getSettingsUrl(community.id),
      onClick: () => trackNavigationEvent(TrackingEvent.GO_TO_SET_ACCESS),
      dataQA: 'community-checklist-option__button-set-access',
      key: 'components-communityChecklist-set_community_access',
    },
  ];

  return (
    <InfoCard
      dataQA="launch-community-checklist"
      title={t('components-communityChecklist_title', 'Launch your Community')}
      isCompact
    >
      <p>
        {t(
          'components-communityChecklist-explanation_text',
          'Follow these steps to set up and launch your community.'
        )}
      </p>
      {communityChecklistOptions.map((option) => checklistOption(option))}
      <Button
        appearance="default"
        ariaLabel={t(
          'components-communityChecklist-publish_button',
          'Publish Community'
        )}
        className={cx('community-checklist-publish__button')}
        color={community.primaryColor}
        data-qa="community-checklist-option__button-publish"
        href={getPublishUrl(community.id)}
        key="components-communityChecklist-publish_button"
        onClick={() =>
          trackNavigationEvent(TrackingEvent.GO_TO_PUBLISH_COMMUNITY)
        }
      >
        <span className={cx('community-checklist-option__container')}>
          {t(
            'components-communityChecklist-publish_button',
            'Publish Community'
          )}
          <Icon
            className={cx('community-checklist-publish__icon')}
            name="arrow-right"
          />
        </span>
      </Button>
      <AdminOnlyIndicator />
    </InfoCard>
  );
};

export default CommunityChecklist;
