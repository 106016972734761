import React from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@thinkific/toga-react';

import TrackingEvent from 'constants/trackingEvents';
import UserAvatar from 'components/UserAvatar';
import { a11yOnClick } from 'helpers/interactions';
import { goToPostPage } from 'utils/routerHelpers';
import { useCommunityContext } from 'components/CommunityContext';

import styles from './TrendingPost.module.scss';

const cx = classNames.bind(styles);

interface TrendingPostProps {
  author: {
    fullName: string | null;
    id: string;
    avatarUrl: string | null;
  } | null;
  commentCount: number;
  title: string;
  id: string;
}

const TrendingPost: React.FC<TrendingPostProps> = ({
  author,
  commentCount,
  title,
  id,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { community, trackEvent } = useCommunityContext();

  const handlePostClick = () => {
    goToPostPage(router, community.id, id);

    trackEvent(TrackingEvent.TRENDING_POST_CLICKED, {});
  };

  return (
    <li
      aria-label={t('shared-read_full_post', 'Read full post: {{title}}', {
        title,
      })}
      className={cx('trending-post__item')}
      data-qa="trending-post__item"
      {...a11yOnClick<HTMLLIElement>(() => handlePostClick())}
    >
      <Tooltip title={author?.fullName}>
        <UserAvatar
          avatarUrl={author?.avatarUrl}
          className={cx('trending-post__avatar')}
          size="xs"
          srcSet={`${author?.avatarUrl}?width=32 1x, ${author?.avatarUrl}?width=32&dpr=2 2x, ${author?.avatarUrl}?width=32&dpr=3 3x`}
          userId={author?.id || ''}
          username={author?.fullName || ''}
        />
      </Tooltip>
      <div className={cx('trending-post__item__post')}>
        <span
          className={cx('trending-post__item__post--title')}
          data-qa="trending-post-title"
        >
          {title}
        </span>
        <span
          className={cx('trending-post__item__post--subtitle')}
          data-qa="trending-post-comment"
        >
          {t('shared-comment_amount', '{{count}} comment', {
            count: commentCount,
          })}
        </span>
      </div>
    </li>
  );
};

export default TrendingPost;
