import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';

import styles from './TrendingPostsSkeleton.module.scss';

const cx = classNames.bind(styles);

const TrendingPostSkeleton: React.FC = () => (
  <div className={cx('trending-post-skeleton')}>
    <div className={cx('trending-post-skeleton__avatar')} />
    <div className={cx('trending-post-skeleton__body')}>
      <SkeletonLine width="80px" />
      <SkeletonLine width="160px" />
    </div>
  </div>
);

const TrendingPostsSkeleton: React.FC = () => {
  return (
    <div
      className={cx('trending-posts-skeleton__container')}
      data-qa="trending-posts-skeleton"
    >
      <SkeletonLine height="16px" width="60%" />
      <div className={cx('trending-posts-skeleton__body')}>
        <TrendingPostSkeleton />
        <TrendingPostSkeleton />
      </div>
    </div>
  );
};

export default TrendingPostsSkeleton;
