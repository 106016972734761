import React, { useEffect, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { features } from 'constants/featureFlags';
import { getLayout as getSiteLayout } from 'components/Layouts/SiteLayout';
import { pathnames } from 'utils/routerHelpers';
import { SCREEN_LG } from 'utils/constants';
import { setGlobalCSSVariables } from 'helpers/styleHelpers';
import { SpaceDetails } from 'components/SpaceDetails';
import { useCommunityContext } from 'components/CommunityContext';
import { useSiteFeaturesContext } from 'components/SiteFeaturesContext';
import { EventPreviewProvider } from 'components/Contexts/EventPreview/EventPreviewContext';
import { useWindowSize } from 'hooks/useWindowSize';
import CommunityActions from 'components/CommunityActions';
import CommunityBrandingBadge from 'components/CommunityBrandingBadge';
import CourseDetails from 'components/CourseCard/CourseDetails';
import CommunityChecklist from 'components/CommunityChecklist';
import TrendingPosts from 'components/TrendingPosts/TrendingPosts';
import EventPreviewCard from 'components/Events/components/EventPreviewCard/EventPreviewCard';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';

import styles from 'pages/[communityId]/index.module.scss';

const cx = classNames.bind(styles);

const MainLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname } = router;
  const { width } = useWindowSize();
  const { community, site, featureFlags } = useCommunityContext();
  const { exceededPlanSpaces } = useSiteFeaturesContext();

  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];
  const isCommunitiesAsAProductEnabled =
    featureFlags[features.COMMUNITIES_AS_A_PRODUCT];

  useEffect(() => {
    setGlobalCSSVariables(community.primaryColor);
  }, [community]);

  const spaceDetailPages = [
    pathnames.spaces.space,
    pathnames.spaces.new,
    pathnames.spaces.edit,
  ];
  const courseCardPages = [
    pathnames.spaces.space,
    pathnames.spaces.new,
    pathnames.spaces.edit,
  ];
  const eventCardPages = [pathnames.events.new, pathnames.events.edit];
  const isHomePage = pathname === pathnames.home;
  const isNewSpacePage = pathname === pathnames.spaces.new;

  const showSpaceDetails = spaceDetailPages.includes(pathname);
  const includeCourseCard = courseCardPages.includes(pathname);
  const includeEventCard = eventCardPages.includes(pathname);

  const showRightColumn = width >= SCREEN_LG;
  const spaceId = router.query?.spaceId as string;

  const shouldShowCommunitiesChecklist =
    !community.published && isHomePage && isCommunitiesAsAProductEnabled;

  return (
    <div
      className={cx('community-wrapper', 'is-spaces-in-communities-enabled')}
    >
      <EventPreviewProvider>
        <CommunityActions className={cx('desktop-navigation')} />
        <main className={cx('main-content')} data-qa="main-content">
          {children}
        </main>
        {showRightColumn && (
          <section
            aria-label={t('Extra information')}
            className={cx('right-content')}
            data-qa="right-content"
          >
            {showSpaceDetails && (
              <SpaceDetails
                className={cx('desktop-navigation')}
                communityId={community.id}
                spaceId={spaceId}
              />
            )}
            {isCoursesToCommunitiesEnabled && includeCourseCard && (
              <VisibilityCloak hide={isNewSpacePage && exceededPlanSpaces}>
                <CourseDetails />
              </VisibilityCloak>
            )}
            {includeEventCard && <EventPreviewCard />}
            {community.published && isHomePage && <TrendingPosts />}
            {shouldShowCommunitiesChecklist && (
              <VisibilityCloak admins>
                <CommunityChecklist />
              </VisibilityCloak>
            )}
            <CommunityBrandingBadge site={site} />
          </section>
        )}
      </EventPreviewProvider>
    </div>
  );
};

export const getLayout = (page: ReactNode): ReactNode =>
  getSiteLayout(<MainLayout>{page}</MainLayout>);

export default MainLayout;
