/* eslint-disable import/prefer-default-export */
import { PostAttachmentInput } from '__generated__/graphql/legacy/graphql';
import { Attachment } from 'schema/Post/types';
import { Post } from 'types/post';

export const sanitizeAttachments = (
  postAttachment: Attachment | null
): PostAttachmentInput | null | undefined => {
  if (!postAttachment) return undefined;

  const safeFieldNames = [
    'attachmentType',
    'attachmentUrl',
    'fileName',
    'fileSizeInBytes',
    's3Bucket',
    's3Key',
    'filestackId',
    'altText',
  ];

  const attachment = {};

  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const key in postAttachment) {
    if (safeFieldNames.includes(key)) {
      attachment[key] = postAttachment[key];
    }
  }

  // @ts-ignore
  return attachment;
};

export const getTotalNumberOfReplies = (post: Post): number => {
  if (!post) return 0;

  const { replies } = post;

  const result =
    replies?.nodes?.reduce((total, reply) => {
      return total + (reply?.replies?.totalCount || 0);
    }, replies.totalCount) || 0;

  return result || 0;
};
