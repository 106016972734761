import React, { useContext, useState, ReactNode } from 'react';
import { EVENT_IMAGE_PLACEHOLDER } from 'utils/constants';

export interface EventPreview {
  imageUrl: string;
  setImageUrl(val: string): void;
  altText: string;
  setAltText(val: string): void;
  title?: string;
  setTitle(val: string): void;
  startTime: string;
  setStartTime(val: string): void;
  endTime: string;
  setEndTime(val: string): void;
  timezone: string;
  setTimezone(val: string): void;
  clearPreview(): void;
}

interface EventPreviewContextProviderProps {
  children: ReactNode;
}

const EventPreviewContext = React.createContext({} as EventPreview);

const useEventPreviewContext = (): EventPreview =>
  useContext(EventPreviewContext);

const EventPreviewProvider: React.FC<EventPreviewContextProviderProps> = ({
  children,
}) => {
  const [imageUrl, setImageUrl] = useState<string>(EVENT_IMAGE_PLACEHOLDER);
  const [altText, setAltText] = useState<string>('');
  const [title, setTitle] = useState<string>();
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const clearPreview = () => {
    setImageUrl(EVENT_IMAGE_PLACEHOLDER);
    setAltText('');
    setTitle('');
    setStartTime('');
    setEndTime('');
    setTimezone('');
  };

  return (
    <EventPreviewContext.Provider
      value={{
        imageUrl,
        setImageUrl,
        altText,
        setAltText,
        title,
        setTitle,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        timezone,
        setTimezone,
        clearPreview,
      }}
    >
      {children}
    </EventPreviewContext.Provider>
  );
};

export { useEventPreviewContext, EventPreviewContext, EventPreviewProvider };
