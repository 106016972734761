import React from 'react';
import classNames from 'classnames/bind';

import {
  POWERED_BY_THINKIFIC,
  POWERED_BY_THINKIFIC_ALT,
} from 'utils/constants';

import styles from './CommunityBrandingBadge.module.scss';

const cx = classNames.bind(styles);

interface BadgeProps {
  site: {
    whiteLabellingActivated: boolean;
    subdomain: string;
  };
}

const CommunityBrandingBadge: React.FC<BadgeProps> = ({ site }) => {
  if (site.whiteLabellingActivated) return null;
  return (
    <div className={cx('community-branding-badge')}>
      <a
        className={cx('community-branding-badge__link')}
        href={`https://www.thinkific.com/?ref=${site.subdomain}-community-poweredby`}
        rel="noopener"
        target="_blank"
      >
        <img
          alt={POWERED_BY_THINKIFIC_ALT}
          className={cx('community-branding-badge__svg')}
          src={POWERED_BY_THINKIFIC}
        />
      </a>
    </div>
  );
};

export { CommunityBrandingBadge as default };
